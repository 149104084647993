import * as React from "react";
import { Container, Row, Col } from 'react-bootstrap';

const WorkDetailSummary = ({ workInfo, summary }) => {

  const client = (workInfo.client === '') ? '-' : workInfo.client;

  const TitleContent = () => {
    return (
      <Row>
        <Col>
          <h1 dangerouslySetInnerHTML={{ __html: workInfo.title }} />
        </Col>
      </Row>
    );
  };

  const AgencyContent = () => {
    if (!summary.agency) {
      return null;
    }
    return (
      <Col xs={12} className="workData">
        <h4 className="titleLabel">Agency</h4>
        <div>{summary.agency}</div>
      </Col>
    );
  }

  const ScopesContent = () => {
    if (summary.scopes.length === 0) {
      return null;
    }
    return (
      <Col xs={12} className="workData">
        <h4 className="titleLabel">Scopes</h4>
        <div>{summary.scopes.join(', ')}</div>
      </Col>
    );
  };

  const AwardsContent = () => {
    return (
      <Col xs={12} className="workData">
        <h4 className="titleLabel">Awards</h4>
        <div>
          <ul>
            {summary.awards.length === 0
              ? <li>-</li>
              : summary.awards.map((award, index) => (
                <li key={index}>{award}</li>
              ))
            }
          </ul>
        </div>
      </Col>
    );
  };

  return (
    <Container fluid className="workHeader">
      {TitleContent()}
      <Row>
        <Col xs={12} lg={6}>
          <Container fluid className="p-0">
            <Row>
              <Col className="outline">
                <h4 className="titleLabel">Outline</h4>
                {summary.outline()}
                {workInfo.url !== '' &&
                  <div className="links">
                    <a href={workInfo.url} target="_blank" rel="noreferrer">Go to Website<span>↗</span></a>
                  </div>
                }
              </Col>
            </Row>
          </Container>
        </Col>
        <Col xs={12} lg={6}>
          <Container fluid className="p-0">
            <Row className="mb-md-0">
              <Col xs={6} className="workData">
                <h4 className="titleLabel">Client</h4>
                <div>{client}</div>
              </Col>
              <Col xs={6} className="workData">
                <h4 className="titleLabel">Year</h4>
                <div>{workInfo.year}</div>
              </Col>
            </Row>
            <Row>
              {AgencyContent()}
              {ScopesContent()}
              {AwardsContent()}
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default WorkDetailSummary;
